.currency-dropdown {
  position: absolute;
  top: 84px;
  right: 108px;
  width: 14%;
  min-width: 160px;
  max-height: 240px;
  background-color: var(--redo-colors-background-bg-primary);
  border-radius: 10px;
  box-shadow: 0 0 17px rgb(0 0 0 / 0.2);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 11;
  overflow-y: scroll;
}

@media screen and (max-width: 600px) {
  .currency-dropdown {
    top: 48px;
    right: 36px;
  }
}

.currency-option {
  height: 36px;
  padding: 16px 4px;
  border-radius: var(--redo-corner-xsmall-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
    background-color: var(--redo-surface-medium-color);
  }
}

.currency-text {
  color: #7f7f7f;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }

  &.mobile {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.invisible {
  visibility: hidden;
}
