.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color-override, #f9f9f9);
}
.page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  position: relative;
}

.brand {
  color: #181818;
  display: flex;
  align-items: center;
  gap: 10px;
}

.brand-container {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 32px;
  display: flex;
}

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.logo {
  height: 24px;
}

.store-return {
  color: #181818;
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
}

.store-return-block {
  position: absolute;
  top: 32px;
  left: 72px;
}

.nav-bar {
  position: relative;
  height: 96px;
  top: 0px;
  width: 100%;
}

.nav-bar-content {
  display: flex;
  justify-content: space-between;
  color: #181818;
  align-items: center;
  background-color: #fff;
  padding: 48px;
  box-shadow: 0 0 17px rgb(0 0 0 / 0.2);
  z-index: 1;
}

.back-arrow {
  width: 100%;
  display: flex;
  justify-content: start;
}

.side-buttons {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.account {
  display: flex;
  justify-content: end;
  align-items: center;
}

.nav-bar-logo {
  max-width: 200px;
  height: 64px;
  object-fit: contain;
  display: flex;
  justify-content: center;
}

.hidden-but-accessible {
  opacity: 0;
  width: 1px;
  height: 1px;
}

.account-text {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4px;
}

.avatar {
  height: 40px;
  width: 40px;
  background-color: #e4e5ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

.avatar-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.account-dropdown {
  position: absolute;
  top: 76px;
  right: 48px;
  width: 12%;
  min-width: 160px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 17px rgb(0 0 0 / 0.2);
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: calc(var(--redo-spacing) * 2);
  z-index: 11;
}

.account-menu-list {
  list-style-type: none;
}

.account-menu-list-item {
  height: 36px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-menu-list-item:hover {
  background-color: var(--redo-surface-medium-color);
}

.chevron-down {
  margin-left: 8px;
}

.name-text {
  font-weight: bold;
}

.gray-text {
  color: #7f7f7f;
}

.cart-total-text {
  margin-right: 5px;
  margin-top: 6px;
  font-size: 15px;
}

.text-success {
  color: #21b021;
}

.text-danger {
  color: #c21c1c;
}

.cart-container {
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.cart {
  margin-right: 20px;
  /* cursor: pointer; */
}

.cart-size-bubble {
  background-color: var(--redo-colors-background-bg-error-solid);
  color: white;
  position: absolute;
  border-radius: 50%;
  padding: 2px;
  font-size: var(--redo-body-small-text-size);
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 14px;
  right: 9px;
  top: -5px;
}

@media screen and (max-width: 600px) {
  .nav-bar-content {
    padding: 24px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  }
  .nav-bar {
    height: 56px;
  }
  .account-dropdown {
    top: 40px;
    right: 12px;
  }
  .nav-bar-logo {
    height: 44px;
  }
  .back-arrow {
    width: 33%;
  }
  .image-wrapper {
    width: 33%;
    display: flex;
    justify-content: center;
  }
  .side-buttons {
    width: 33%;
  }
  .chevron-down {
    display: flex;
    align-items: center;
  }
}
