.image {
  width: 90px;
  min-width: 90px;
  height: auto;
  border-radius: 6px;
  object-fit: contain;

  &.small {
    width: 60px;
    min-width: 60px;
  }
}
