.totalsContainer {
  & button p {
    padding-left: 0px;
    font-weight: 400;
  }
}

/** help mom I mangled an arbiter component */
.totals-header div button {
  height: 16px !important;
}
