.outlined-card {
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 12px;
  box-shadow: 0 4px 6px -2px #10182808;
  box-shadow: 0 12px 16px -4px #10182814;
}

.type-animation-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--redo-colors-text-text-primary);
}
