@import "~@redotech/redo-web/redo.css";

:root {
  --return-app-body-font-family: var(--redo-body-font-family);
  --return-app-title-font-family: var(--redo-title-font-family);
}

html * {
  background: none;
  border: none;
  color: inherit;
  text-decoration: inherit;
}

html body,
.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  height: auto;
}

body {
  background-color: var(--redo-colors-background-bg-primary);
  color: #181818;
  font-family: var(--return-app-body-font-family);
  font-size: var(--redo-body-medium-text-size);
  line-height: 1.5;
}

html {
  min-width: auto;
}
