.map-container {
  flex: 2 0 0px;
  height: 350px;
  & > div > div {
    border-radius: 6px;
  }
}

.locations-text-container {
  flex: 1 0 0px;
  overflow-y: auto;
  min-height: 0px;
}

@media screen and (max-width: 800px) {
  .locations-container {
    flex-direction: column-reverse;
    gap: calc(var(--redo-spacing) * 2) !important;
    flex: 1 0 100%;
    min-height: 700px;
  }

  .locations-text-container {
    flex: 2 0 0%;
    overflow-y: auto;
    min-height: 0px;
  }

  .map-container {
    width: 100%;
    height: 350px;
    flex: none;
    align-content: stretch;
    justify-self: stretch;
  }
}
